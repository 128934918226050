import Cookies from 'js-cookie';
import {getAliParams} from '@base/util/protector/ali';
import {getTongdunParams} from '@base/util/protector/tongdun';

export default function getPasscodeOptions() {
    return {
        ...getAliParams(),
        ...getTongdunParams(),
        did: Cookies.get('GID') || '',
        // 来源区分：0-非品增，1-品增
        sourceType: 0,
        // 操作来源：1-安卓，2-IOS，3-PC，4-H5
        operateSource: 3
    };
}
