// 特殊符号正则
const symbolReg = /[`~!@#$%^&*()\-=_+[\]{}\\|;:'",<.>/?，。？（）！—·¢£€¥₱…§]/;
// 小写字母正则
const alphaReg = /[a-z]/;
// 大写字母正则
const alphaCapReg = /[A-Z]/;
// 数字正则
const numReg = /[0-9]/;
// 检测是否含有非法字符
const invalidReg = /[^`~!@#$%^&*()\-=_+[\]{}\\|;:'",<.>/?，。？（）！—·¢£€¥₱…§a-zA-Z0-9]/;

// 输入字符必须属于数字/小写字母/大写字母/特殊符号
export function validateInput(password) {
    return !invalidReg.test(password);
}

// 密码需要包含大写字母、小写字母、数字和符号的三种以上组合
export function validatePassword(password) {
    let matched = 0;
    matched += symbolReg.test(password) ? 1 : 0;
    matched += alphaReg.test(password) ? 1 : 0;
    matched += alphaCapReg.test(password) ? 1 : 0;
    matched += numReg.test(password) ? 1 : 0;
    return matched >= 3;
}

export function validatePasswordLength(password) {
    return password.length >= 8 && password.length <= 16;
}
