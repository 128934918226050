const {createLogger, format, transports} = !process.browser && require('winston');
const {combine, timestamp, label} = !process.browser && format || {};
const moment = !process.browser && require('moment');
const envConfig = process.env.CONFIG.env;

const APP_NAME = process.env.APP_NAME || 'pc-web';

// !process.browser && require('winston-daily-rotate-file');

const parseCookie = cookie =>
    cookie.split(';').reduce((result, item) => {
        const [key, value] = item.split('=');
        result[key.trim()] = value;
        return result;
    }, {});

// 接口响应后，把data格式化为记录日志需要的格式
const infoLogConfig = (msg, data) => {
    const {config, res, response, code, message} = data;

    let infoData = {};
    infoData.msg = msg;

    // 基础配置
    if (config) {
        const {headers, url, log, trackId = 'trackSsrsuccess'} = config;
        if (headers) {
            const {referer, cookie} = headers;
            if (cookie) {
                const {uid, gr_user_id, sid, GID} = parseCookie(cookie);
                infoData = {
                    ...infoData,
                    ...{uid, gr_user_id, sid, GID}
                };
            }

            infoData = {
                ...infoData,
                ...{
                    trackId,
                    UA: headers['user-agent'],
                    referer
                }
            };
        }

        infoData = {
            ...infoData,
            ...{url},
            ...log
        };
    }

    // 接口成功
    if (res) {
        infoData.status = 200;
    }

    // 接口失败
    if (response) {
        const {status, data, headers} = response;
        // 这里没看明白
        infoData.status = (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) ? 1000 : status;
        infoData.errorMsg = headers['grpc-message']
            ? decodeURIComponent(headers['grpc-message'])
            : data ? data.msg || data : '网络请求失败，请稍重试~';
    }

    return infoData;
};

const errorLogConfig = (msg, data) => {
    const {code, response, config, message} = data;

    let errorDara = {};
    errorDara.msg = msg;

    if (config) {
        const {headers, url, log, trackId} = config;
        if (headers) {
            const {reffer, cookie} = headers;

            if (cookie) {
                const {uid, gr_user_id, sid, GID} = parseCookie(cookie);
                errorDara = {
                    ...errorDara,
                    ...{uid, gr_user_id, sid, GID, cookie}
                };
            }

            errorDara = {
                ...errorDara,
                trackId,
                UA: headers['user-agent'],
                reffer
            };
        }


        errorDara = {
            ...errorDara,
            ...{url},
            ...log
        };
    }

    if (response) {
        const {status, data, headers} = response;
        errorDara.status = (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1 ? 500 : status);
        errorDara.errorMsg = headers['grpc-message']
            ? decodeURIComponent(`${headers['grpc-message']}`)
            : data ? data.msg || data : '网络请求失败，请稍重试';
    }

    return errorDara;
};

const renderTimeLogConfig = (msg, data) => ({msg, ...data});

const pageErrorLogConfig = data => data;

let loggerInit;
if (!loggerInit) {
    loggerInit = !process.browser && createLogger({
        format: combine(
            label({label: APP_NAME}),
            timestamp({format: () => moment().format('YYYY-MM-DD HH:mm:ss:SSS Z')}),
            format.json()
        ),
        transports: [

            new transports.File({
                level: 'error',
                filename: `${envConfig.logDir}/error.log`,
                datePattern: 'YYYYMMDD'
            }),
            new transports.File({
                level: 'info',
                filename: `${envConfig.logDir}/app.log`,
                datePattern: 'YYYYMMDD'
            })
        ]
    });
}

// 收集服务端页面渲染报错日志
let loggerPageErrorInit;
if (!loggerPageErrorInit) {
    loggerPageErrorInit = !process.browser && createLogger({
        format: combine(
            label({label: APP_NAME}),
            timestamp({format: () => moment().format('YYYY-MM-DD HH:mm:ss:SSS Z')}),
            format.json()
        ),
        transports: [
            new transports.File({
                level: 'error',
                filename: `${envConfig.logDir}/error.log`,
                datePattern: 'YYYYMMDD'
            })
        ]
    })
}

// 收集服务端渲染耗时日志
// let loggerRenderTimeInit;
// if(!loggerRenderTimeInit) {
//     loggerRenderTimeInit = !process.browser && createLogger({
//         format: combine(
//             label({label: 'pc-web'}),
//             timestamp({format: () => moment().format('YYYY-MM-DD HH:mm:ss:SSS Z')}),
//             format.json()
//         ),
//         transports: [
//             new transports.DailyRotateFile({
//                 level: 'info',
//                 filename: './log/render_time.log',
//                 datePattern: 'YYYYMMDD'
//             })
//         ]
//     })
// }

class Logger {
    constructor(msg = '', data = {}) {
        this.msg = msg;
        this.data = data;
    }

    // 记录服务端接口响应成功
    info(msg, data) {
        loggerInit.info(infoLogConfig(msg, data));
    }

    // 服务端接口响应失败
    error(msg, data) {
        loggerInit.error(errorLogConfig(msg, data));
    }

    // 服务端渲染，trackid + path
    render(msg, data) {
        loggerInit.info(renderTimeLogConfig(msg, data));
    };

    // // 服务端渲染失败
    // pageError(msg, data) {
    //     loggerPageErrorInit.info(pageErrorLogConfig(msg, data));
    // };

    // 服务端响应时间，暂时用不到，需要观察服务端接口相应时间的时候会用到
    // renderTime(msg, data) {
    //     loggerRenderTimeInit.info(renderTimeLogConfig(msg, data));
    // }
}

export default Logger;
