const mapType = {
    0: '满减',
    1: '直减',
    2: '联报',
    3: '满赠',
    4: '赠送'
};

export default class PromotionDetail {
    constructor({
        type, 
        promotionNumber, 
        descList, 
        explain = '', 
        time = '', 
        tips = '', 
        presentDesc = '', 
        presentRange = [],
        countDown,
        countDown: {remainingTime, remainingCount} = {}
    }) {
        this.type = type;
        this.strType = mapType[type];
        this.promotionNumber = promotionNumber;
        this.descList = descList;
        this.explain = explain;
        this.time = time;
        this.tips = tips;
        this.presentDesc = presentDesc;
        this.presentRange = presentRange;
        // 是否显示倒计时
        this.countDown = !!countDown;
        if (this.countDown) {
            if (remainingTime) {
                this.remainingTime = +remainingTime;
            }
            if (remainingCount) {
                this.remainingCount = remainingCount;
            }
        }
    }
}
