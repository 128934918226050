/* eslint-disable no-tabs */
import Cookies from 'js-cookie';

const DID = Cookies.get('did') || Cookies.get('Did') || 'web';
const GID = Cookies.get('GID') || '';
const config = {
    headers: {}
};
if (DID) {
    config.headers.did = DID;
}
if (GID) {
    config.headers.gid = GID;
}


export default config;
