/* eslint-disable */
const loadOpts = {
    src: '//g.alicdn.com/AWSC/AWSC/awsc.js',
    type: 'text/javascript'
};

const initOpts = {
    innerHTML: `
        var uabModule;
        var webUmidToken;

        //人机识别模块，只需初始化一次
        AWSC.use("uab", function (state, uab) {
            if(state === "loaded") {
                uabModule = uab;
            }
        });
        //设备指纹模块，得到设备token，只需初始化一次
        AWSC.use("um", function (state, um) {
            if(state === "loaded") {
                um.init({
                    appName: 'saf-aliyun-com',
                }, function (initState, result) {
                    if(initState === 'success') {
                        webUmidToken = result.tn;
                    }
                });
            }
        });
    `,
    body: true
};

export const AliOpts = [loadOpts, initOpts];

export function getAliParams() {
    return {
        uaToken: window.uabModule ? window.uabModule.getUA() : '',
        webUmidToken: window.webUmidToken || ''
    };
}