/* eslint-disable */
const loadOpts = {
    src: '//static.tongdun.net/monitor/monitor.js',
    type: 'text/javascript',
    charset: 'utf-8',
    async: 'async',
    body: true,
    crossorigin: 'anonymous'
};

const initOpts = {
    innerHTML: `
        window._fmOpt = {
            partner: 'gaotu',
            appName: 'gaotu_web',
            token: 'gaotu' + '-' + new Date().getTime() + '-' + Math.random().toString(16).substr(2),
            fmb: true,
            success(data) {
                window.blackbox = data;
                // 在成功完成采集后，success 回调中可以获取到 blackbox，在获取到 blackbox 后传 输过程中请进行 url 编码
                // console.log('blackbox: ', data);
            },
            fpHost: 'https://fp.tongdun.net'
        };

        var cimg = new Image(1,1);
        cimg.onload = function() {
            _fmOpt.imgLoaded = true;
        };
        cimg.src = 'https://fp.tongdun.net/fp/clear.png?partnerCode=erzcn&appName=erzcn_web&tokenId=' + _fmOpt.token;
        var fm = document.createElement('script');
        fm.type = 'text/javascript';
        fm.async = true;
        fm.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'static.tongdun.net/v3/fm.js?ver=0.1&t=' + (new Date().getTime()/3600000).toFixed(0);
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(fm, s);
    `,
    body: true
};

export const TongdunOpts = [loadOpts, initOpts];

export function getTongdunParams() {
    return {
        blackBox: window.blackbox || ''
    };
}