/**
 * 参考:https://www.cnblogs.com/ricolee/p/localstorage-expiretime.html
 */
const storage = {

    /**
     * set 存储方法
     * @param {String} key      键
     * @param {String} value    值
     * @param {Number} expired  过期时间，以毫秒为单位，非必须
     */
    set(key, value, expired) {
        if (!key) {
            return;
        }
        try {
            const source = {
                data: value
            };
            if (expired) {
                source.expires = Date.now() + expired;
            }
            window.localStorage.setItem(key, JSON.stringify(source));
        }
        catch (e) {}
    },

    /**
     * get 获取方法
     * @param {String} key 键
     */
    get(key) {
        if (!key) {
            return;
        }
        try {
            let val = window.localStorage.getItem(key);
            val = JSON.parse(val);
            const expired = val.expires || Date.now() + 1;
            const now = Date.now();
            if (now >= expired) {
                this.remove(key);
                return null;
            }
            return val.data;
        }
        catch (e) {}
    },

    /**
     * remove 删除方法
     * @param {String} key 键
     */
    remove(key) {
        if (!key) {
            return;
        }
        try {
            window.localStorage.removeItem(key);
        }
        catch (e) {}
    },

    /**
     * clear 清除方法
     */
    clear() {
        try {
            window.localStorage.clear();
        }
        catch (e) {}
    }
};

export default storage;
